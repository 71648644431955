




















































































































































































































































































































































































































































































































































































































































.bodytop {
  background: #fff;
}
.agentHome {
  padding: 1rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
}
.agentHomeBox {
  display: flex;
  .cellbox {
    width: 33.3%;
    background: #fff;
    padding: 1rem;
    .cell {
      background: #fff;
      box-shadow: 0px 1px 21px 0px rgba(38, 46, 45, 0.06);
    }
  }
}
.personCount {
  display: flex;
  padding-top: 1.2rem;
  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .countNum {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333;
  }
  .countTitle {
    font-size: 0.75rem;
    color: #666;
    font-weight: 400;
  }
}
.bodybottom {
  background: #fff;
  margin-top: 1.15rem;
  height: 100%;
}
